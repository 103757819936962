define('Silk_AlgoliaSearch/js/silk_productbrands',[
    'jquery'
], function ($) {
    "use strict";

    var agProductBrands = {
        init: function(requestUrl) {
            if (!requestUrl.length) {
                return false;
            }

            $(document).on('agproductbrands:init', {}, function() {
                $("[data-hit-agproductbrand='1']").each(function() {
                    var algoPriceHtml =$(this).find(".algo_price").html();
                    if (algoPriceHtml){
                        if(algoPriceHtml.indexOf("sup") == -1){
                            var sharCount = algoPriceHtml.lastIndexOf(".");
                            if(sharCount == -1){
                                var first = algoPriceHtml.substring(0,1);
                                var maincon = algoPriceHtml.substr(1)+".";
                                var last = '00';
                            }else{
                                var first = algoPriceHtml.substring(0,1);
                                var maincon = algoPriceHtml.substring(1,sharCount+1);
                                var last = algoPriceHtml.substr(sharCount+1);
                            }
                            var newAlgoPriceHtml = '<sup>'+first+'</sup>'+maincon+'<sup>'+last+'</sup>';
                            $(this).find(".algo_price").html(newAlgoPriceHtml);
                        }
                    }
                });
            });

            var hitBrandState = false;
            var compareProIds = [];
            setInterval(function () {
                if(!hitBrandState){
                    var hitProductIds = [];
                    $(".msrp-message").hover(function(){
                        $(this).parent().parent().find(".algo-tip-bd").css("display","block");
                        $(this).parent().parent().find(".algo-tip-cor").css("display","block");
                        $(this).parent().parent().find(".algo-tip-cor_s").css("display","block");
                    },function(){
                        $(this).parent().parent().find(".algo-tip-bd").css("display","none");
                        $(this).parent().parent().find(".algo-tip-cor").css("display","none");
                        $(this).parent().parent().find(".algo-tip-cor_s").css("display","none");
                    });
                    $("[data-hit-agproductbrand='1']").each(function() {
                        hitProductIds.push($(this).attr('data-objectid'));

                        var algoPriceHtml =$.trim($(this).find(".algo_price").html());
                        if (algoPriceHtml){
                            if(algoPriceHtml.indexOf("sup") == -1){
                                var sharCount = algoPriceHtml.lastIndexOf(".");
                                if(sharCount == -1){
                                    var first = algoPriceHtml.substring(0,1);
                                    var maincon = algoPriceHtml.substr(1)+".";
                                    var last = '00';
                                }else{
                                    var first = algoPriceHtml.substring(0,1);
                                    var maincon = algoPriceHtml.substring(1,sharCount+1);
                                    var last = algoPriceHtml.substr(sharCount+1);
                                }
                                var newAlgoPriceHtml = '<sup>'+first+'</sup>'+maincon+'<sup>'+last+'</sup>';
                                $(this).find(".algo_price").html(newAlgoPriceHtml);
                            }
                        }
                    });
                    if (hitProductIds.length && hitProductIds !== compareProIds) {
                        hitBrandState = true;
                        compareProIds = hitProductIds;
                        $.ajax({
                            url: requestUrl,
                            method: 'POST',
                            cache: false,
                            global: false,
                            data: {
                                product_ids: hitProductIds
                            },
                            success: function (result) {
                                for (var i in result) {
                                    var resultObj = result[i];
                                    var prId = resultObj.productId;
                                    var prHtml = resultObj.html;
                                    $("[data-objectid='"+prId+"']").attr("data-hit-agproductbrand", 0);
                                    // $("[data-objectid='"+prId+"']").find(".amshopby-option-link").remove();
                                    // $("[data-objectid='"+prId+"']").attr("data-hit-agproductbrand", 0).find('.result-thumbnail').after(prHtml);
                                }
                                hitBrandState = false;
                            }
                        });
                    }
                }
            },3000 );

        }
    };

    return agProductBrands;
});

