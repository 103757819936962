/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_PageBuilder/js/resource/jarallax/jarallax-wrapper',[
    'Magento_PageBuilder/js/resource/jarallax/jarallax'
], function(jarallax){
    'use strict';

    window.jarallax = window.jarallax || jarallax;
});

