define(
    'W2M_Smtparts/js/parttype-list',[
        'jquery',
        'mage/storage',
    ],
    function ($, storage) {

    var productPartTypes = [];
    function getPartTypesFromResponse() {
        var types = [];
        productPartTypes.forEach(function (productPartType) {
            if(!types.includes(productPartType.part_type)) {
                types.push(productPartType.part_type);
            }
        });
        return types;
    };

    function getCodeFromType(type) {
        var code = '';
        productPartTypes.forEach(function (productPartType) {
            if(productPartType.part_type == type) {
                code = productPartType.product_code;
            }
        });
        return code;
    };

    return {
        getItems: function() {
            var defer = $.Deferred();
            if(productPartTypes.length > 0) {
                defer.resolve(getPartTypesFromResponse());
            } else {
                this.getFromServer().done(function (response) {
                    defer.resolve(getPartTypesFromResponse());
                });
            }
            return defer.promise();
        },

        getFromServer: function() {
            return storage.get(
                '/rest/V1/smtparts/productparttype/list',
                false
            ).done(function (response) {
                productPartTypes = response;
                //defer.resolve(getPartTypesFromResponse(response));
            });
        },

        getProductCodeFromType: function(type) {

            var defer = $.Deferred();
            if(productPartTypes.length > 0) {
                defer.resolve(getCodeFromType(type));
            } else {
                this.getFromServer().done(function (response) {
                    defer.resolve(getCodeFromType(type));
                });
            }
            return defer.promise();
        },

        setNewProductPartTypes: function (list) {
            productPartTypes = list;
        }
    }

});

